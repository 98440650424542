import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import favicon from "@assets/favicon.ico";

const Head = props => {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `);

  const siteMeta = query.site.siteMetadata;
  const url = `${siteMeta.siteUrl}${props.location.pathname}`;

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${siteMeta.title}`}
        defaultTitle={siteMeta.title}
        htmlAttributes={{ lang: "no" }}
      >
        <link rel="shortcut icon" href={favicon} />
        <meta
          name="facebook-domain-verification"
          content="ixibe6ynpwi8a1wieqfhm3tuvuai77"
        />
      </Helmet>
      <SubHead {...{ url, ...siteMeta }} title={props.title} />
    </>
  );
};

const SubHead = props => {
  return (
    <Helmet>
      <title>{props.title}</title>

      {props.description && (
        <meta name="description" content={props.description} />
      )}
      {props.url && <meta property="og:url" content={props.url} />}
      <meta property="og:title" content={props.title} />
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      {props.image && <meta property="og:image" content={props.image} />}
      <meta name="twitter:title" content={props.title} />
      {props.description && (
        <meta name="twitter:description" content={props.description} />
      )}
      {props.image && <meta name="twitter:image" content={props.image} />}
    </Helmet>
  );
};

export default Head;
