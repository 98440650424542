import React from "react";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";

import Button from "@components/Button";

import * as styles from "./styles.module.css";

const AppStoreButtons = ({ className }) => {
  const { sanityAppStoreButtons: data } = useStaticQuery(
    graphql`
      query {
        sanityAppStoreButtons {
          id
          buttons {
            label
            link
          }
        }
      }
    `,
  );

  const { buttons } = data;

  return (
    <div className={cx(className, styles.container)}>
      {buttons.map(({ link, label }) => (
        <Button
          as="a"
          key={label}
          href={link}
          className={styles.button}
          target="_blank"
          rel="noreferrer noopener"
        >
          {label}
        </Button>
      ))}
    </div>
  );
};

export default AppStoreButtons;
