exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bestill-js": () => import("./../../../src/pages/bestill.js" /* webpackChunkName: "component---src-pages-bestill-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gavekort-js": () => import("./../../../src/pages/gavekort.js" /* webpackChunkName: "component---src-pages-gavekort-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lastned-js": () => import("./../../../src/pages/lastned.js" /* webpackChunkName: "component---src-pages-lastned-js" */),
  "component---src-pages-musikken-js": () => import("./../../../src/pages/musikken.js" /* webpackChunkName: "component---src-pages-musikken-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */)
}

