import { useCallback, useEffect, useRef } from "react";
import useScroll from "@hooks/useScroll";

const useAnimation = (func, dependencies = []) => {
  const callback = useCallback(func, [func, ...dependencies]);

  const scrollY = useRef(0);
  const requestRef = useRef();

  useEffect(() => {
    scrollY.current = window.scrollY;
  }, []);

  useScroll(() => {
    scrollY.current = window.scrollY;
  }, []);

  useEffect(() => {
    const animate = () => {
      callback(scrollY.current);
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [callback]);
};

export default useAnimation;
