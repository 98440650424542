import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import * as styles from "./styles.module.css";
import Text from "@components/Text";

const Button = ({
  as: Element = "button",
  className,
  onClick,
  children,
  ...props
}) => {
  return (
    <Element
      className={cx(className, styles.button)}
      onClick={onClick}
      {...props}
    >
      <Text as="span" variant="titleThreeSmall">
        {children}
      </Text>
    </Element>
  );
};

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default Button;
