import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Head from "@components/Head";
import Header from "@components/Header";
import Footer from "@components/Footer";

import * as styles from "./styles.module.css";

const Layout = ({ children, pageContext, className, location }) => {
  return (
    <>
      <Head location={location} />
      <Header />
      <main
        className={cx(className, {
          [styles.empty]: pageContext.layout === "empty",
        })}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  empty: PropTypes.bool,
};

export default Layout;
