import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { ReactComponent as Logo } from "@assets/logo.svg";

import { clamp } from "@utils/math";
import { BREAKPOINTS } from "@utils/breakpoints";

import useAnimation from "@hooks/useAnimation";
import useResize from "@hooks/useResize";

import Button from "@components/Button";
import Menu from "@components/Menu";
import AppStoreButtons from "@components/AppStoreButtons";

import * as styles from "./styles.module.css";

const mobileRange = [0, 32];
const desktopRange = [0, 24];

const Header = () => {
  const [showMenu, toggleMenu] = useState(false);
  const [hideTransition, toggleTransition] = useState(false);

  const headerEl = useRef();
  const stickyEl = useRef();

  const transformRange = useRef(desktopRange);

  useEffect(() => {
    transformRange.current =
      window.innerWidth >= BREAKPOINTS.xLarge ? desktopRange : mobileRange;
  }, []);

  useResize(() => {
    if (window.innerWidth >= BREAKPOINTS.xLarge) {
      toggleMenu(false);
      toggleTransition(true);

      transformRange.current = desktopRange;
    } else {
      toggleTransition(false);

      transformRange.current = mobileRange;
    }
  }, []);

  useAnimation(scrollY => {
    const y = clamp(scrollY, ...transformRange.current);

    stickyEl.current.style.transform = `translate3d(0, ${-y}px, 0)`;
  }, []);

  useEffect(() => {
    if (!showMenu) return;

    const onClick = e => {
      if (!headerEl.current.contains(e.target)) {
        toggleMenu(false);
      }
    };

    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [showMenu]);

  return (
    <header ref={headerEl} className={styles.header}>
      <h1 className={styles.logo}>
        <Link to="/">
          <span className="visuallyhidden">Trall</span>
          <Logo />
        </Link>
      </h1>

      <Menu
        {...{
          showMenu,
          hideTransition,
          toggleMenu,
          onPageChange: () => toggleMenu(false),
        }}
      />

      <div ref={stickyEl} className={styles.stickyWrapper}>
        <Button className={styles.appStoreButtonsWrapper}><a href="/lastned">Last ned Trall</a></Button>
        
        <Button className={styles.menuButton} onClick={() => toggleMenu(true)}>
          Meny
        </Button>
      </div>
    </header>
  );
};

export default Header;
