import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Images from "@components/Images";

import List from "@components/List";
import SocialLink from "@components/SocialLink";
import { ReactComponent as Arrow } from "@assets/arrow.svg";

import * as styles from "./styles.module.css";

import Tilgjengelighet from "../../assets/tilgjengelighet/Tilgjengelighet.pdf"

const Footer = () => {
  const { sanityFooter: data } = useStaticQuery(
    graphql`
      query {
        sanityFooter {
          links {
            title
            slug {
              current
            }
          }
          contact {
            _key
            _type
            label
            link
          }
          socials {
            _key
            _type
            type
            link
          } 
          tilgjengelighetserklaring {
            asset {
              url
            }
          }
          _rawTilgjengelighetserklaring 
          logos {
            alt
            asset {
              id
              gatsbyImageData(width: 200, placeholder: NONE)
            }
          }
        }
      }
    `,
  );

  const { links, logos, contact, socials, tilgjengelighetserklaring, _rawTilgjengelighetserklaring } = data;
  const tilgjengelighetBeskrivelse = _rawTilgjengelighetserklaring.beskrivelse;
  const pdf = tilgjengelighetserklaring.asset.url;

  const listProps = {
    items: [
      ...links.map(({ title, slug }) => ({
        label: title,
        link: `/${slug?.current}`,
      })),
      { ...contact, linkType: "external" },
      
      {
        label: tilgjengelighetBeskrivelse,
        linkType: "external",
        link: pdf
      },
    ],
    
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.listWrapper}>
        <List
          {...listProps}
          variant="titleThreeSmall"
          enhancerStart={<Arrow />}
        >
          <li className={styles.socialsListItem}>
            <Arrow />
            {socials.map((social, index) => (
              <SocialLink key={index} {...social} />
            ))}
          </li>
        </List>
      </div>
      <div className={styles.imageWrapper}>
        <h3 className={styles.heading}>Støttespillere</h3>
          <div className={styles.imageWrapperInner}>
            <Images images={logos} className={styles.image} />
          </div>
      </div>
    </footer>
  );
};

export default Footer;
