import { useEffect, useCallback } from "react";

const useScroll = (func, dependencies = []) => {
  const callback = useCallback(func, [func, ...dependencies]);

  useEffect(() => {
    window.addEventListener("scroll", callback);

    return () => {
      window.removeEventListener("scroll", callback);
    };
  }, [callback]);
};

export default useScroll;
