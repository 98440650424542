import React from "react";
import PropTypes from "prop-types";

import { GatsbyImage } from "gatsby-plugin-image";

const Images = ({ className, images }) => {
  return (
    <>
      {images.map(({ asset, alt }) => (
        <div key={asset?.id} className={className}>
          <GatsbyImage image={asset?.gatsbyImageData} alt={alt} />
        </div>
      ))}
    </>
  );
};

Images.propTypes = {
  className: PropTypes.string,
  images: PropTypes.array,
};

export default Images;
