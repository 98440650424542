import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";
import * as styles from "./styles.module.css";

const Text = ({
  as: Element = "p",
  className,
  variant = "body",
  children,
  ...props
}) => {
  return (
    <Element className={cx(className, styles[variant])} {...props}>
      {children}
    </Element>
  );
};

Text.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  variant: PropTypes.oneOf([
    "titleOne",
    "titleTwo",
    "titleThree",
    "titleThreeSmall",
    "titleFour",
    "quote",
    "body",
  ]),
};

export default Text;
