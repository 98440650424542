// extracted by mini-css-extract-plugin
export var sizes = "\"@styles/variables.css\"";
export var xLargeScreen = "75rem";
export var mediumScreen = "48rem";
export var logo = "styles-module--logo--3TNHk";
export var closeButton = "styles-module--closeButton--2QM4E";
export var menu = "styles-module--menu--1XOmP";
export var links = "styles-module--links--2HtU4";
export var hideTransition = "styles-module--hideTransition--3di9Q";
export var showMenu = "styles-module--showMenu--HLZgY";
export var linkArrow = "styles-module--linkArrow--3CVDd";
export var linkWrapper = "styles-module--linkWrapper--3nbo-";
export var link = "styles-module--link--3KDpI";
export var isActive = "styles-module--isActive--3D9_M";