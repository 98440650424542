// extracted by mini-css-extract-plugin
export var sizes = "\"@styles/variables.css\"";
export var mediumScreen = "48rem";
export var largeScreen = "59rem";
export var footer = "styles-module--footer---eRqn";
export var listWrapper = "styles-module--listWrapper--WBxdf";
export var socialsListItem = "styles-module--socialsListItem--2IM_X";
export var imageWrapper = "styles-module--imageWrapper--DrGoQ";
export var heading = "styles-module--heading--XSZP2";
export var image = "styles-module--image--1QS3r";
export var imageWrapperInner = "styles-module--imageWrapperInner--3PYyr";