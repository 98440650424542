import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import cx from "classnames";

import Text from "@components/Text";
import * as styles from "./styles.module.css";

const ListItem = ({ item, enhancerStart, variant }) => (
  <li className={styles.listItem}>
    {enhancerStart && (
      <div className={styles.enhancerStart}>{enhancerStart}</div>
    )}

    {typeof item === "object" ? (
      item.linkType === "external" ? (
        <a href={item.link} target="_blank" rel="noreferrer noopener">
          <Text as="span" variant={variant}>
            {item.label}
          </Text>
        </a>
      ) : (
        <Link to={item.link}>
          <Text as="span" variant={variant}>
            {item.label}
          </Text>
        </Link>
      )
    ) : (
      <Text as="span" variant={variant}>
        {item}
      </Text>
    )}
  </li>
);

const List = ({
  items,
  enhancerStart,
  variant = "titleThree",
  children,
  className,
}) => {
  return (
    <ul className={cx(className, styles.list)}>
      {items.map((item, index) => (
        <ListItem
          key={index}
          item={item}
          variant={variant}
          enhancerStart={enhancerStart}
        />
      ))}

      {children}
    </ul>
  );
};

List.propTypes = {
  items: PropTypes.array.isRequired,
  enhancerStart: PropTypes.object,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "titleOne",
    "titleTwo",
    "titleThree",
    "titleThreeSmall",
    "titleFour",
    "quote",
    "body",
  ]),
};

export default List;
