import React from "react";

import { ReactComponent as Facebook } from "@assets/facebook.svg";
import { ReactComponent as Instagram } from "@assets/instagram.svg";

import * as styles from "./styles.module.css";

const socialMap = {
  facebook: () => <Facebook />,
  instagram: () => <Instagram />,
};

const SocialLink = ({ type, link }) => {
  const Icon = socialMap[type];

  return (
    <a
      href={link}
      className={styles.socialLink}
      target="_blank"
      rel="noreferrer noopener"
    >
      {Icon ? <Icon /> : null}
    </a>
  );
};

export default SocialLink;
