import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

import { ReactComponent as Logo } from "@assets/logo.svg";
import { ReactComponent as Arrow } from "@assets/arrow.svg";
import { ReactComponent as Cross } from "@assets/cross.svg";

import Button from "@components/Button";
import Text from "@components/Text";

import * as styles from "./styles.module.css";

const links = [
  /*{
    label: "Priser",
    link: "/priser",
  },*/
  {
    label: "Bestill",
    link: "/bestill",
  },
  {
    label: "Musikken",
    link: "/musikken",
  },
  {
    label: "Om oss",
    link: "/om-oss",
  },
  {
    label: "Gavekort",
    link: "/gavekort",
  },
  {
    label: "Tips og råd",
    link: "/tips-og-rad",
  },
  {
    label: "Spørsmål og svar",
    link: "/faq",
  },
];

const initDelay = 0.2;
const itemDelay = 0.1;

const PageLink = ({ label, link, index, showMenu, onPageChange }) => (
  <li
    className={styles.linkWrapper}
    style={{
      transitionDelay: `${
        showMenu
          ? initDelay + index * itemDelay
          : (links.length - 1 - index) * itemDelay
      }s`,
    }}
  >
    <div className={styles.linkArrow}>
      <Arrow />
    </div>

    <Link
      className={styles.link}
      activeClassName={styles.isActive}
      to={link}
      onClick={onPageChange}
    >
      <Text as="span" variant="titleThreeSmall">
        {label}
      </Text>
    </Link>
  </li>
);

const Menu = ({ showMenu, toggleMenu, hideTransition, onPageChange }) => (
  <nav
    className={cx(styles.menu, {
      [styles.showMenu]: showMenu,
      [styles.hideTransition]: hideTransition,
    })}
  >
    <Link
      aria-hidden="true"
      className={styles.logo}
      to="/"
      onClick={onPageChange}
    >
      <span className="visuallyhidden">Trall</span>
      <Logo />
    </Link>

    <ul className={styles.links}>
      {links.map(({ label, link }, index) => (
        <PageLink
          key={label}
          {...{
            index,
            label,
            link,
            showMenu,
            onPageChange,
          }}
        />
      ))}
    </ul>

    <Button className={styles.closeButton} onClick={() => toggleMenu(false)}>
      <Cross /> <span className="visuallyhidden">Lukk</span>
    </Button>
  </nav>
);
export default Menu;
